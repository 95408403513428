iframe {
    display: none;
}

.leftside-menu {
    display: none;
}

.navbar-custom {
    margin-left: 0;
    width: 100vw;
}

.content-page {
    margin-left: 0;
    width: 100vw;
}

.mobile-menu {
    display: none;
    position: absolute;
    width: 100vw;
    height: 100vh;
    left: 0;
    background-color: aliceblue;
    /* transition: all .25s ease-in-out; */
}

    .mobile-menu.responsive {
        display: block;
    }

.my-auto {
    padding: 0.75rem;
}

.s-label {
    width: 40px;
    height: 20px;
}

.slider:before {
    height: 13px;
    width: 13px;
}

.s-input:checked + .slider:before {
    transform: translateX(19px);
}

.filter-with-scroll-box {
    gap: 10px;
}

.card-header {
    padding: 15px;
}

.card-body {
    padding: 15px;
}
/* .shareholder-info {
  width: 100px;
} */
button.accordion-button > * {
    flex: 0 0 42%;
}

.transaction-btn {
    padding: 3px 10px;
}

.content {
    width: 100vw;
}

@media (min-width: 768px) {
    .leftside-menu {
        display: unset !important;
    }

    .navbar-custom {
        margin-left: var(--tz-leftbar-width);
        width: unset;
    }

    .content-page {
        margin-left: var(--tz-leftbar-width);
        width: unset;
    }

    .my-auto {
        padding: 2.25rem !important;
    }

    .topbar {
        display: none !important;
    }

    .mobile-menu {
        display: none !important;
    }

    .s-label {
        width: 60px;
        height: 34px;
    }

    .slider:before {
        height: 26px;
        width: 26px;
    }

    .s-input:checked + .slider:before {
        transform: translateX(26px);
    }

    .filter-with-scroll-box {
        gap: 20px;
    }

    .card-header {
        padding: var(--tz-card-cap-padding-y) var(--tz-card-cap-padding-x);
    }

    .card-body {
        padding: var(--tz-card-spacer-y) var(--tz-card-spacer-x);
    }
    /* .shareholder-info {
    width: auto;
  } */
    button.accordion-button > * {
        flex: unset !important
    }

    .transaction-btn {
        padding: 3px 20px;
    }

    .content {
        width: auto !important;
    }
}
